<template>
  <div v-loading="tableLoading" >
    <el-table :data="obj.datalists" class="table">
      <el-table-column type="index" width="40"></el-table-column>
      <el-table-column label="Document ID" prop="recCode" width="140" class="font-bold"></el-table-column>
      <el-table-column label="Image" width="240">
        <template slot-scope="scope">
          <img :src="scope.row.icon" alt  height="80px"/>
        </template>
      </el-table-column>
      <el-table-column label="Vector Website" prop="webType" width="140" class="font-bold"></el-table-column>
      <el-table-column label="Format" prop="formatName" width="80" class="font-bold"></el-table-column>
      <el-table-column label="Price" prop="price" width="60" class="font-bold"></el-table-column>
      <el-table-column label="Download Time" prop="buyTime" class="font-gray"></el-table-column>
      <el-table-column label="Status">
        <template slot-scope="scope"  v-if="scope.row.source != 16">
          <div v-if="scope.row.orderType == null">
            <el-button type="danger" size="small" @click="download(scope.row,scope.$index)">Re-download</el-button>
          </div>
          <div v-else>
            <a rel="nofollow" :href="scope.row.downurl" target="_blank" ><el-button type="primary" size="small" >Download</el-button></a>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="obj.total"
      v-if="obj.total > 10"
    ></el-pagination>
  </div>
</template>

<script>
import {reDownload} from '../../../api/downLoad'
export default {
  props: {
    obj:{
      type:Object
    },
    // 带有默认值的对象
    data: {
      type: Array
    }
  },
  computed: {
    tableData() {
      return this.data;
    }
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      tableLoading: false
    };
  },
  methods: {
    download(item,index) {
      this.tableLoading = true;
      let buydata = {
        imageID:item.recCode,
        webType:item.webType,
        format:item.format
      }
      reDownload(buydata).then((result) => {
        this.tableLoading = false;
        this.$set(this.obj.datalists[index],"downurl",result.downloadUrl);
        this.$set(this.obj.datalists[index],"downurl2",result.downloadUrl2);
        this.obj.datalists[index].orderType = '1';
      }).catch((error) => {
        this.tableLoading = false;
      })
    },
    download2(item) {
      this.tableLoading = true;
      let buydata = {
        imageID:item.recCode,
        webType:item.webType,
        format:item.format
      }
      reDownload(buydata).then((result) => {
        this.tableLoading = false;
        window.open(result.downloadUrl2,"_blank");
      }).catch((error) => {
        this.tableLoading = false;
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.$emit("sizeChange", val);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.$emit("pageChange", val);
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>


</style>